@font-face {
  font-family: 'Los_Andes';
  font-weight: 300;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Light.otf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 400;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Regular.otf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 900;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Bold.otf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 300;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Light_Italic.otf);
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 400;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Regular_Italic.otf);
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 900;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Bold_Italic.otf);
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Los_Andes';
  font-weight: 400;
  src: url(/fonts/Los_Andes_Lota_Grotesque_Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: 'GE';
  font-weight: 300;
  src: url(/fonts/GE_SS_Two_Light.otf);
  font-display: swap;
}

@font-face {
  font-family: 'GE';
  font-weight: 400;
  src: url(/fonts/GE_SS_Two_Medium.otf);
  font-display: swap;
}
@font-face {
  font-family: 'GE';
  font-weight: 900;
  src: url(/fonts/GE_SS_Two_Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Roboto_Numbers';
  font-weight: 400;
  src: url(/fonts/Roboto-Medium.ttf);
  unicode-range: U+30-39;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(/fonts/Roboto-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  src: url(/fonts/Lato-Light.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  src: url(/fonts/Lato-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  font-weight: 900;
  src: url(/fonts/Lato-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Roihu-Regular';
  font-weight: 400;
  src: url(/fonts/Roihu-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: 'MTN';
  font-weight: 300;
  src: url(/fonts/MTNBRIGHTERSANS-LIGHT.TTF);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'MTN';
  font-weight: 400;
  src: url(/fonts/MTNBRIGHTERSANS-MEDIUM.TTF);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'MTN';
  font-weight: 900;
  src: url(/fonts/MTNBRIGHTERSANS-BOLD.TTF);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'MTN';
  font-weight: 300;
  src: url(/fonts/MTNBRIGHTERSANS-LIGHTITALIC.TTF);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'MTN';
  font-weight: 400;
  src: url(/fonts/MTNBRIGHTERSANS-MEDIUMITALIC.TTF);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'MTN';
  font-weight: 900;
  src: url(/fonts/MTNBRIGHTERSANS-BOLDITALIC.TTF);
  font-display: swap;
  font-style: italic;
}

/*  */
@font-face {
  font-family: 'MUSICMTN';
  font-weight: 300;
  src: url(/fonts/MusicMTNBrighterSans-Light.ttf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'MUSICMTN';
  font-weight: 400;
  src: url(/fonts/MusicMTNBrighterSans-Medium.ttf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'MUSICMTN';
  font-weight: 900;
  src: url(/fonts/MusicMTNBrighterSans-Bold.ttf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'MUSICMTN';
  font-weight: 300;
  src: url(/fonts/MusicMTNBrighterSans-LightItalic.ttf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'MUSICMTN';
  font-weight: 400;
  src: url(/fonts/MusicMTNBrighterSans-MediumItalic.ttf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'MUSICMTN';
  font-weight: 900;
  src: url(/fonts/MusicMTNBrighterSans-BoldItalic.ttf);
  font-display: swap;
  font-style: italic;
}

/* STC-BH fonts */
@font-face {
  font-family: 'STCBH';
  font-weight: 300;
  src: url(/fonts/STC-Light.otf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'STCBH';
  font-weight: 400;
  src: url(/fonts/STC-Medium.otf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'STCBH';
  font-weight: 900;
  src: url(/fonts/STC-Bold.otf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'STCBH';
  font-weight: 300;
  src: url(/fonts/STC-ThinItalic.otf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'STCBH';
  font-weight: 400;
  src: url(/fonts/STC-Italic.otf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'STCBH';
  font-weight: 900;
  src: url(/fonts/STC-BoldItalic.otf);
  font-display: swap;
  font-style: italic;
}

/* GLO-NGA fonts */
@font-face {
  font-family: 'GLONGA';
  font-weight: 300;
  src: url(/fonts/Rubik-Light.ttf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GLONGA';
  font-weight: 400;
  src: url(/fonts/Rubik-Regular.ttf);
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'GLONGA';
  font-weight: 900;
  src: url(/fonts/Rubik-Bold.ttf);
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GLONGA';
  font-weight: 300;
  src: url(/fonts/Rubik-LightItalic.ttf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GLONGA';
  font-weight: 400;
  src: url(/fonts/Rubik-Italic.ttf);
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'GLONGA';
  font-weight: 900;
  src: url(/fonts/Rubik-BoldItalic.ttf);
  font-display: swap;
  font-style: italic;
}

html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  user-select: none;
  min-height: -webkit-fill-available;
}

a,
a:hover,
a:active,
a:visited {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

input[type='text'] {
  font-size: 16px;
}

.react-tel-input input[type='tel'] {
  height: 40px !important;
  width: 100% !important;
  border-radius: 30px !important;
}

.react-tel-input .country-list {
  width: 90vw;
  max-width: 360px !important;
  color: #6b6b6b;
}

.react-tel-input .country-list .country-name {
  margin: 0;
}

.react-tel-input .country-list .dial-code {
  margin: 0 6px;
}
.react-tel-input .flag-dropdown,
.react-tel-input .selected-flag,
.react-tel-input .flag-dropdown.open-dropdown .selected-flag,
.react-tel-input .flag-dropdown.open-dropdown {
  border-radius: 30px 0 0 30px;
}

.react-tel-input .flag-dropdown .selected-flag {
  padding: 0;
}

.react-tel-input .flag-dropdown .selected-flag .flag {
  margin: -5px 5px 0 5px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
